import { sendTrackingEvent } from "./sendTrackingEvent"

export type TrackingEvent = Record<string, any> | undefined

export const generateTrackingEvent = ({
  event,
  action,
  ...rest
}: TrackingEvent = {}) => {
  // The event and action can differ since it's a generic method.
  // Therefore, it's impossible to have a common type for such generic events.
  // @ts-ignore
  if (!event || !action || !sendTrackingEvent[event]) {
    return
  }

  // @ts-ignore
  return sendTrackingEvent[event]({
    gaEvent: {
      action,
      ...rest,
    },
  })
}
