import { NodeRenderer } from "@contentful/rich-text-react-renderer"
import {
  Hyperlink as HyperlinkNode,
  INLINES,
} from "@contentful/rich-text-types"

import { BaseLink } from "src/components/common/base-link"

export const Hyperlink: NodeRenderer = (node) => {
  if (node.nodeType !== INLINES.HYPERLINK) {
    return null
  }

  const hyperlink = node as HyperlinkNode
  const href = hyperlink.data.uri
  const text = hyperlink.content[0].value

  return <BaseLink href={href}>{text}</BaseLink>
}
