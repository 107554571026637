import { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./TableMobile.module.scss"
import { TableColumn, TableRowItem } from "./types"

const bem = create(styles, "TableMobile")

export type TableMobileProps = {
  title?: string
  rows: TableRowItem[]
  columns: TableColumn["mobile"][]
  className?: string
}

export const TableMobile: FC<TableMobileProps> = ({
  title = "table",
  rows,
  columns,
  className,
}) => {
  if (!columns.length || !rows.length) {
    return null
  }

  return (
    <table title={title} className={bem(undefined, undefined, className)}>
      <tbody>
        {rows.map((item, itemIndex) =>
          columns.map((column, columnIndex) => (
            <tr
              key={`${column.field}-${columnIndex}`}
              className={bem("row", { highlighted: itemIndex % 2 !== 1 })}
              data-cy="table-row"
            >
              {!column.options?.hideName && <th>{column.title}</th>}
              <td
                key={`${column}-${columnIndex}`}
                className={bem("cell", undefined, column.className)}
                colSpan={column.options?.hideName ? 2 : 1}
              >
                {item[column.field]}
              </td>
            </tr>
          )),
        )}
      </tbody>
    </table>
  )
}
