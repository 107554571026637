import { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./TableDesktop.module.scss"
import { TableColumn, TableRowItem } from "./types"

const bem = create(styles, "TableDesktop")

export type TableDesktopProps = {
  title?: string
  rows: TableRowItem[]
  columns: TableColumn["desktop"][]
  className?: string
}

export const TableDesktop: FC<TableDesktopProps> = ({
  title = "table",
  rows,
  columns,
  className,
}) => {
  if (!columns.length || !rows.length) {
    return null
  }

  return (
    <table title={title} className={bem(undefined, undefined, className)}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.field}>{column.title}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((item, itemIndex) => (
          <tr key={`${item}-${itemIndex}`} data-cy="table-row">
            {columns.map((column, columnIndex) => (
              <td
                key={`${column.field}-${columnIndex}`}
                className={bem("cell", undefined, column.className)}
              >
                {item[column.field]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
