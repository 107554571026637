import { Table, Paragraph } from "@contentful/rich-text-types"
import chunk from "lodash/chunk"
import partition from "lodash/partition"

import {
  TableColumnCommonProps,
  TableRowItem,
} from "src/components/common/table"

type ParseContentfulTableFunction = (table: Table) => {
  columns: TableColumnCommonProps[]
  rows: TableRowItem[]
}

const flattenCell = (content: Paragraph[]) => {
  return content
    .map((paragraph) => {
      if (paragraph.nodeType !== "paragraph") return
      return paragraph.content.map((text) => {
        if (text.nodeType !== "text") return
        return text.value
      })
    })
    .flat(Infinity)
    .filter(Boolean)
    .join(" ")
}

export const parseContentfulTable: ParseContentfulTableFunction = (table) => {
  const list = table.content
    .map((item) => {
      return item.content
    })
    .flat(1)

  const [headers, cells] = partition(list, (item) => {
    return item.nodeType === "table-header-cell"
  })

  const columns: TableColumnCommonProps[] = headers.map((header) => {
    const field = flattenCell(header.content)
    return {
      field,
      title: field,
    }
  })

  const rows = chunk(cells, columns.length).map((row) => {
    return row.reduce(
      (prev, curr, currIndex) => ({
        ...prev,
        [columns[currIndex].field]: flattenCell(curr.content),
      }),
      {},
    )
  })

  return {
    columns,
    rows,
  }
}
