import { ImageSource } from "src/components/common/image"

import { getContentfulAssetUrl } from "./getContentfulAssetUrl"

export type ImageSourceSize = {
  /** Maximum screen size to show this source (breakpoint) */
  maxScreenSize: number

  /** Optional scaling of an image. Default 1x. */
  scales?: number[]
} & ({ width: number; height?: number } | { width?: number; height: number })

export type ImageSourceSizes = [ImageSourceSize, ...ImageSourceSize[]]

// Used in Bold Teaser
export type ImageType = "photo" | "graphic"

export type ImageSourceOptions = {
  /** Enforce a specific image format. */
  format?: "jpg" | "png" | "webp"

  /** Image cropping behavior. See: https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping */
  fit?: "pad" | "fill" | "scale" | "crop" | "thumb"

  /** Image quality between 0 and 100. */
  quality?: number
}

/**
 * Util to create image sources with the use of the contentful image api, based
 * on a contentful image asset. The specified sizes are defined by a maximum
 * screen size and at least a with or a height. If both, width and height, are
 * defined the image will be cropped basde on the given `fit` option.
 *
 * @param asset the contentful image asset
 * @param sizes the source sizes to be created
 * @param options the contentful image api options
 * @returns the image sources
 */
export function createContentfulImageSources(
  url: string | undefined,
  sizes: ImageSourceSizes,
  options: ImageSourceOptions = {},
): ImageSource[] {
  if (!url) {
    return []
  }

  const src = getContentfulAssetUrl(url, "images")

  // Supported contentful image api params, mapping for option keys.
  const params = {
    fit: "fit",
    format: "fm",
    width: "w",
    height: "h",
    quality: "q",
  } as const

  return sizes.map((size) => {
    const { width, height, scales = [1] } = size

    const srcSet: string[] = []

    scales.forEach((scale) => {
      const url = new URL(src)
      const settings = {
        ...options,
        width: width ? width * scale : undefined,
        height: height ? height * scale : undefined,
      }

      Object.entries(settings).forEach((setting) => {
        const key = setting[0] as keyof typeof settings
        const value = setting[1]
        if (value === undefined) {
          return
        }

        const name = params[key]
        url.searchParams.append(name, value.toString())
      })

      srcSet.push(`${url.toString()} ${scale}x`)
    })

    return { ...size, src, srcSet }
  })
}
