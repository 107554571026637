import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer"
import {
  BLOCKS,
  Document,
  INLINES,
  MARKS,
  Table,
} from "@contentful/rich-text-types"
import { Entry } from "contentful-management"
import { FC, Fragment } from "react"

import { ContentfulAssetImage } from "../../ContentfulAssetImage"
import { ContentfulFallback } from "../../ContentfulFallback"

import { create } from "src/helpers/bem"
import { ContentfulRichTextData } from "src/types/contentful/autoservice"
import { Asset } from "src/types/contentful/contentful-type-patches"

import { EmbeddedEntry } from "./blocks/EmbeddedEntry"
import { ContentfulTable } from "./blocks/table/ContentfulTable"
import styles from "./ContentfulRichText.module.scss"
import { EntryHyperlink } from "./inlines/EntryHyperlink"
import { Hyperlink } from "./inlines/Hyperlink"

const bem = create(styles, "ContentfulRichText")

const richTextOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },

  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node) => (
      <div className={bem("asset")}>{EmbeddedEntry(node, undefined)}</div>
    ),
    [INLINES.ENTRY_HYPERLINK]: EntryHyperlink,
    [INLINES.HYPERLINK]: Hyperlink,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const entry = node.data.target as Entry
      const contentType = entry?.sys?.contentType?.sys?.id

      if (!contentType) {
        return
      }

      const modifiers = {
        "is-list": contentType === "sectionIconList",
      }

      return (
        <div className={bem("asset", modifiers)}>
          {EmbeddedEntry(node, undefined)}
        </div>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const asset = node.data.target as Asset
      const contentType = asset.fields?.file?.contentType

      if (!contentType || !/^image\/.*/.test(contentType)) {
        return <ContentfulFallback data={node.data.target} />
      }

      return (
        <div className={bem("asset")}>
          <ContentfulAssetImage data={asset} />
        </div>
      )
    },
    [BLOCKS.TABLE]: (node) => (
      <div className={bem("asset")}>
        <ContentfulTable table={node as Table} />
      </div>
    ),
    [BLOCKS.PARAGRAPH]: (node) => {
      // Don't render empty paragraphs
      if (
        node.content.length === 0 ||
        node.content.every(
          (child) => child.nodeType === "text" && child.value.trim() === "",
        )
      ) {
        return null
      }

      return (
        <p>
          {node.content.map((child, index) => (
            <Fragment key={index}>
              {documentToReactComponents(child as Document, richTextOptions)}
            </Fragment>
          ))}
        </p>
      )
    },
  },
}

export type ContentfulRichTextOptions = {
  isList?: boolean
  isNote?: boolean
}

export type ContentfulRichTextProps = {
  content?: ContentfulRichTextData
  className?: string
  options?: ContentfulRichTextOptions
  alignment?: "left" | "center" | null
}

export const ContentfulRichText: FC<ContentfulRichTextProps> = ({
  content,
  className,
  options,
  alignment,
}) => {
  if (!content) {
    return null
  }

  const modifiers = {
    "is-list": !!options?.isList,
    "is-note": !!options?.isNote,
    [alignment || "left"]: true,
  }

  return (
    <div className={bem(undefined, modifiers, className)}>
      <>{documentToReactComponents(content as Document, richTextOptions)}</>
    </div>
  )
}
