import { Table as TableType } from "@contentful/rich-text-types"
import { FC } from "react"

import { Table } from "src/components/common/table"

import { parseContentfulTable } from "./parseContentfulTable"

type ContentfulTableProps = {
  table?: TableType
}

export const ContentfulTable: FC<ContentfulTableProps> = ({ table }) => {
  if (!table?.content?.length) return null

  const { columns, rows } = parseContentfulTable(table)

  return <Table columns={columns} rows={rows} />
}
