import { FC, useMemo } from "react"

import {
  createContentfulImageSources,
  ImageSourceOptions,
  ImageSourceSizes,
} from "../../helpers/contentful/createContentfulImageSources"
import { Image } from "src/components/common/image"
import { ContentfulAssetData } from "src/types/contentful/autoservice"

export const defaultImageSizes: ImageSourceSizes = [
  { maxScreenSize: 480, width: 480, scales: [1, 2] },
  { maxScreenSize: 640, width: 640, scales: [1, 2] },
  { maxScreenSize: 750, width: 750, scales: [1, 2] },
  { maxScreenSize: 828, width: 828, scales: [1, 2] },
  { maxScreenSize: 1080, width: 1080, scales: [1, 1.5] },
  { maxScreenSize: 1280, width: 1280, scales: [1, 1.5] },
  { maxScreenSize: 1440, width: 1440, scales: [1] },
  { maxScreenSize: 1920, width: 1920, scales: [1] },
]

export const defaultImageOptions: ImageSourceOptions = {
  format: "webp",
  fit: "fill",
  quality: 70,
}

export interface ContentfulAssetImageProps {
  data: ContentfulAssetData
  className?: string
  alt?: string
  imageSizes?: ImageSourceSizes
  imageOptions?: ImageSourceOptions
}

export const ContentfulAssetImage: FC<ContentfulAssetImageProps> = ({
  data,
  className,
  alt,
  imageSizes = defaultImageSizes,
  imageOptions = defaultImageOptions,
}) => {
  const sources = useMemo(
    () =>
      createContentfulImageSources(
        data?.fields?.file?.url,
        imageSizes,
        imageOptions,
      ),
    [data?.fields?.file?.url, imageSizes, imageOptions],
  )

  return (
    <Image
      className={className}
      sources={sources}
      alt={alt ?? data?.fields?.description ?? ""}
    />
  )
}
