import { FC } from "react"

import { screenBreakpoints } from "src/const/screenBreakpoints"
import { Media } from "src/helpers/media"

import { TableDesktop } from "./TableDesktop"
import { TableMobile } from "./TableMobile"
import { TableRowItem, TableColumn, TableColumnCommonProps } from "./types"

export type TableProps = {
  title?: string
  rows: TableRowItem[]
  columns: TableColumnCommonProps[]
  columnsMobile?: TableColumn["mobile"][]
  className?: string
}

export const Table: FC<TableProps> = ({
  columns,
  columnsMobile,
  rows,
  ...commonProps
}) => {
  if (!columns.length || !rows.length) {
    return null
  }

  return (
    <>
      <Media minWidth={screenBreakpoints.md}>
        <TableDesktop {...commonProps} columns={columns} rows={rows} />
      </Media>
      <Media maxWidth={screenBreakpoints.md}>
        <TableMobile
          {...commonProps}
          rows={rows}
          columns={columnsMobile || columns}
        />
      </Media>
    </>
  )
}
