import { NodeRenderer } from "@contentful/rich-text-react-renderer"

import { BaseLink } from "src/components/common/base-link"
import { ContentfulFallback } from "src/components/contentful/ContentfulFallback"
import { ContentfulCommonContentType } from "src/types/contentful"
import { ContentfulPageData } from "src/types/contentful/autoservice"
import { Entry } from "src/types/contentful/contentful-type-patches"

export const EntryHyperlink: NodeRenderer = (node) => {
  const [content] = node.content
  if (content.nodeType !== "text") {
    return null
  }

  const entry = node.data.target as Entry
  const entryType = entry.sys.contentType.sys.id as ContentfulCommonContentType
  const label = content.value
  let href: string | undefined

  switch (entryType) {
    case "page":
      const page = entry as ContentfulPageData
      href = page?.fields?.path
      break

    default:
      return <ContentfulFallback data={entry} />
  }

  return (
    <BaseLink href={href || `./#missing-href-for-${entryType}`}>
      {label}
    </BaseLink>
  )
}
