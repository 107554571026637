import { FC } from "react"

import { ContentfulAssetImage } from "src/components/contentful/ContentfulAssetImage"
import { create } from "src/helpers/bem"
import { ContentfulAssetImageData } from "src/types/contentful/autoservice"

import styles from "./AssetImage.module.scss"

const bem = create(styles, "ContentfulSectionRichtext__BlockAssetImage")

export const BlockAssetImage: FC<{ data: ContentfulAssetImageData }> = ({
  data,
}) => {
  return (
    <figure className={bem()}>
      <ContentfulAssetImage
        className={bem("display")}
        data={data?.fields?.contentfulImageAsset}
        alt={data?.fields?.alt}
      />
      <figcaption className={bem("caption")}>
        {data?.fields?.caption}
      </figcaption>
    </figure>
  )
}
