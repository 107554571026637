import { NodeRenderer } from "@contentful/rich-text-react-renderer"

import { ContentfulSection } from "src/components/contentful/ContentfulSection"
import { ContentfulCommonContentType } from "src/types/contentful"
import { ContentfulAssetImageData } from "src/types/contentful/autoservice"
import { Entry } from "src/types/contentful/contentful-type-patches"

import { BlockAssetImage } from "./AssetImage"

export const EmbeddedEntry: NodeRenderer = (node) => {
  const entry = node.data.target as Entry

  // return if contentType is unavailable to fix the page breaking issue
  if (!entry.sys.contentType) {
    return
  }

  const entryType = entry.sys.contentType.sys.id as ContentfulCommonContentType
  switch (entryType) {
    case "assetImage":
      return <BlockAssetImage data={entry as ContentfulAssetImageData} />

    default:
      return <ContentfulSection data={node.data.target} />
  }
}
