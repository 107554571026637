import { EXTERNAL_SERVICES } from "src/config"

export function getContentfulAssetUrl(
  currentUrl: string | undefined,
  type: "images" | "videos",
) {
  const { contentfulUrl, cacheUrl } =
    EXTERNAL_SERVICES.contentful.assetUrls[type]

  let url = currentUrl

  if (url?.startsWith("//")) {
    url = `https:${url}`
  }

  return url?.replace(contentfulUrl, cacheUrl) || ""
}
